@font-face {
  font-family: "MajorMonoDisplay";
  src: url("./fonts/MajorMonoDisplay-Regular.ttf") format("truetype");
}

body {
  margin: 0.5rem;
}

.app {
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  margin-bottom: 0.5rem;
  font-family: "MajorMonoDisplay", sans-serif;
}

.content {
  display: flex;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                        supported by Chrome, Edge, Opera and Firefox */
}

h1 {
  margin: 0;
  padding-bottom: 1rem;
  white-space: nowrap;
}

.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  box-sizing: border-box;
  overflow: auto;
  background-color: white;
  word-break: break-word;
}
